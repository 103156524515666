import { Injectable } from "@angular/core";
import { catchError, map, tap } from "rxjs/operators";
import {
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  HttpHandler,
  HttpResponse,
} from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { LocalStorageProvider } from "./localStorage";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { throwError } from "rxjs";

/**
 * @fileOverview This file is responsible for adding headers
 *  at the time of API calling. Works as an Interceptor.
 * @author TA
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  counter = 0;
  constructor(
    private localStorageProvider: LocalStorageProvider,
    public localStorage: LocalStorageProvider,
    public toaster: ToastrService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.localStorageProvider.getItem("accesstoken")
      ? this.localStorageProvider.getItem("accesstoken")
      : "";
    if (req.url.includes(environment.API_BASE_URL)) {
      req = req.clone({ headers: req.headers.set("authorization", token) });
      req = req.clone({ headers: req.headers.set("accept-language", "en") });
      req = req.clone({
        headers: req.headers.set("x-api-key", environment.encyptionKey),
      });
      req = req.clone({
        headers: req.headers.set("Content-Type", "application/json"),
      });
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.status == 401) {
            this.toaster.error("Session Expired", "Error");
            this.localStorage.clearAll();
            this.router.navigateByUrl("/login");
          }
        }
        return event;
      }),
      catchError((err: any) => {
        if (err.status == 401) {
          this.toaster.error(err.error.message, "Error");
          this.localStorage.clearAll();
          this.router.navigateByUrl("/login");
        } else {
          this.toaster.error(err.error.message, "Error");
        }
        return throwError(err);
      })
    );
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/auth/auth.module').then(
        (m) => m.AuthModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/theme-wrapper/theme-wrapper.module').then(
        (m) => m.ThemeWrapperModule
      ),
      
  },
  
  // {
  //   path: '/',
  //   loadChildren: () =>
  //     import('./modules/auth/auth.module').then(
  //       (m) => m.AuthModule
  //     ),
  // },
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('./modules/theme-wrapper/theme-wrapper.module').then(
  //       (m) => m.ThemeWrapperModule
  //     ),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

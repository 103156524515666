import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from "@angular/core";

@Directive({
  selector: "[appDrag]",
})
export class DragDirective {
  @HostBinding("style.background") private background = "#eee";

  constructor() {}

  @HostBinding("class.fileover") fileOver!: boolean;
  @Output() fileDropped = new EventEmitter<any>();

  @HostListener("dragover", ["$event"])
  public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = "#999";
  }
  @HostListener("dragLeave", ["$event"])
  public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = "#eee";
  }

  // when file actually dropped:-

  @HostListener("drop", ["$event"]) public ondrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}

import { Component } from "@angular/core";
import { v4 as uuidv4 } from "uuid";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Humans";

  constructor() {
    console.log(uuidv4());
  }
}
